//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import {
    Actionsheet
  } from 'mint-ui';
  import normalHead from '@/views/components/ishop/header.vue'
  import myCart from '@/views/components/ishop/dialogs/myCart'
  import { miceService } from '@/service/miceService'
  // import step2Input from './step2Input.vue'

  export default {
    name: 'step1Input',
    data() {
      return {
        Ifshow: false,
        value: '外卖',
        headValue: {
          name: '录入外卖餐厅',
          haveBack: true,
          haveRule: false,
        },
        actions:[],
        cart: {
          take: true,
          select: 1,
          sum: 1,
          "cityId": '',
          "hospitalId": '',
          itemType: 7,
          tag: '外卖/茶歇',
          shops: [{
            status: "true",
            value: '外卖',
            mealType: "1",
            name: '',
            "recommendType": 2,
            "rowId": 0,
            "shopId": '',
            "imgThumbnail": "",
            "priceAvgVal": 0,
            "regionVal": "",
            "categoryVal": "",
            "tel": "",
            "address": "",
            "checked": true
          }]
        },
        cartShops: {},
        totalNum: 0,
      }
    },
    computed: {
      getCartSelect: function() {
        return this.cart.select;
      },
      getCartSum: function() {
        if(this.cart.sum<0){
          this.cart.sum=0
        }
        return this.cart.sum;
      }
    },
    created() {
      this.arrangeTotalNum()
      
    },
    methods: {
      deleteGroup: function(index) {
        if (this.cart.shops.length > 1) {
          this.cart.shops.splice(index, 1);
          this.cart.select--
          if(this.totalNum) {
            this.cart.sum++
          }
        }
      },
      addGrounp() {
        let maxQty7Type3Total = this.$store.state.ishop.maxQty7Type3//外卖录入数
        let maxQty7Type4Total = this.$store.state.ishop.maxQty7Type4//茶歇录入数
        if(this.cart.shops.length >= this.totalNum) {
          this.toast({
            message: '最多可推荐' + this.totalNum +'家外卖/茶歇',
            duration: 5000,
          });
          return;
        }
        
        // let value = '外卖'
        let item = {
          "recommendType": 2,
          status: "true",
          value: '外卖',
          mealType: "1",
          name: '',
          "recommendType": 2,
          "rowId": 0,
          "shopId": '',
          "imgThumbnail": "",
          "priceAvgVal": 0,
          "regionVal": "",
          "categoryVal": "",
          "tel": "",
          "address": "",
          "checked": true
        }
        //已经添加的外卖数量
        let mealType1Num = this.cart.shops.filter((ele)=>{
        	return ele.mealType == 1; 
        }).length;
        //已经添加的茶歇数量
        let mealType2Num = this.cart.shops.filter((ele)=>{
        	return ele.mealType == 2; 
        }).length;
        //如果添加外卖达标，继续添加则给定茶歇
        if(mealType1Num >= this.$store.state.ishop.maxQty7Type3){
        	item.value = '茶歇';
        	item.mealType = '2'
        }
        //如果添加茶歇达标，继续添加则给定外卖
        if(mealType2Num >= this.$store.state.ishop.maxQty7Type4){
        	item.value = '外卖';
        	item.mealType = '1'
        }
//      if(maxQty7Type3Total==0){
//        item.value = '茶歇'
//      } else if(maxQty7Type4Total==0){
//        item.value = '外卖'
//      }
        this.cart.shops.push(item)
        this.cart.select++
        if(this.totalNum) {
          this.cart.sum--
        }
      },
      arrangeTotalNum () {
      	//添加外卖分为【外卖+茶歇， 之和 == 手工录入数量，目前配置里面的手工录入没有用处，只需判断茶歇+外卖最大数量即可】TODO
        let maxQty7Type2Total = this.$store.state.ishop.maxQty7Type2//手工录入总数
        let maxQty7Type3Total = this.$store.state.ishop.maxQty7Type3//外卖录入数
        let maxQty7Type4Total = this.$store.state.ishop.maxQty7Type4//茶歇录入数
        //以下判断有问题，不再根据外卖手工录入最大数来判断了
//      if(maxQty7Type2Total>0){
//        this.totalNum = maxQty7Type2Total;
//      }
//      if(maxQty7Type2Total<0&&maxQty7Type3Total>0&&maxQty7Type4Total>0){
//        this.totalNum = maxQty7Type3Total + maxQty7Type4Total;
//      }
//      if(maxQty7Type2Total<0&&(maxQty7Type3Total<0||maxQty7Type4Total<0)){
//        this.totalNum = false
//      }
        this.totalNum = maxQty7Type3Total + maxQty7Type4Total;
        if(maxQty7Type3Total==0){
          this.actions = [{
              name: '茶歇',
              method: this.closes
          }]
          this.cart.shops[0].value = '茶歇'
        } else if(maxQty7Type4Total==0){
          this.actions = [{
              name: '外卖',
              method: this.closes
          }]
          this.cart.shops[0].value = '外卖'
        }else{
          this.actions = [{
              name: '外卖',
              method: this.closes
          },{
            name: '茶歇',
            method: this.closes
          }]
        }
        this.queryMyShops();
      },
      queryMyShops: async function() {
        let params = {
          "itemType": 7,
          hospitalId: sessionStorage.getItem("hospitalId"),
          cityId: sessionStorage.getItem('pingRegionId'),
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
        }
        const res = await miceService.queryMyShops(params)

        if (res.data.customed.length > 0) {
          let nodeData = []
          for(var i=0,l=res.data.customed.length;i<l;i++){
            if(!res.data.customed[i].isDead){
              nodeData.push(res.data.customed[i])
            }
          }

          // let node =res.data.customed;
          for(let i=0;i<nodeData.length;i++){
            if(nodeData[i].mealType=='2'){
              nodeData[i].value='茶歇'
            }else if(nodeData[i].mealType=='1'){
              nodeData[i].value='外卖'
            }
          }
          this.cart.shops = nodeData.length>0?nodeData:this.cart.shops

        }
        this.cart.select = this.cart.shops.length
        if(this.totalNum){
          this.cart.sum = this.totalNum - this.cart.shops.length
        }else{
          this.cart.sum = "--"
        }
        
      },
      postMyShops: async function() {
        // 计算出3，4的总数
        let type3Num = 0;
        let type4Num = 0;
        for (var i = 0;i < this.cart.shops.length; i++) {
          if(this.cart.shops[i].mealType == 1){
            type3Num++
          }
          if(this.cart.shops[i].mealType == 2){
            type4Num++
          }
        }
        let maxQty7Type2 = this.$store.state.ishop.maxQty7Type2;//录入总数
        let maxQty7Type3 = this.$store.state.ishop.maxQty7Type3;//录入外卖数
        let maxQty7Type4 = this.$store.state.ishop.maxQty7Type4;//录入茶歇数
        if(maxQty7Type3>=0 && maxQty7Type3 < type3Num){
          this.toast({
            message: '最多可推荐' + maxQty7Type3 +'家外卖',
            duration: 5000,
          });
          return;
        }
        if (maxQty7Type4>=0 && maxQty7Type4 < type4Num) {
          this.toast({
            message: '最多可推荐' + maxQty7Type4 +'家茶歇',
            duration: 5000,
          });
          return;
        }
        let nowShop=[];
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if (this.cart.shops[i].name!='') {
            nowShop.push(this.cart.shops[i])
          }
        }
        this.cart.shops=nowShop;
        //过滤已保存的餐厅 TODO
				nowShop = nowShop.filter((ele)=>{
					return !ele.recommendId
				})
				if(nowShop.length < 1){
					this.toast('您尚未添加新外卖');
					this.isSubmitData = false;
					return false;
				}
        let params = {
          hospitalId:  sessionStorage.getItem("hospitalId"),
          cityId: sessionStorage.getItem('pingRegionId'),
          itemType: 7,
          shops: nowShop,
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
        }
        return false; //TODO
        const res = await miceService.postMyShops(params)
        if(res){this.isSubmitData = false}
        if (res.status) {
          this.$router.push('/mytakeaway');
        }
      },
      select(index) {
        this.Ifshow = !this.Ifshow
        this.cart.shops[index].status = "false"
      },
      closes(item) {
        if (item.name == "外卖") {
          for (var i = 0, length = this.cart.shops.length; i < length; i++) {
            if (this.cart.shops[i].status == "false") {
              this.cart.shops[i].value = "外卖"
              this.cart.shops[i].mealType = 1
              this.cart.shops[i].status = "true"
              return
            }
          }
        } else if (item.name == "茶歇") {
          for (var i = 0, length = this.cart.shops.length; i < length; i++) {
            if (this.cart.shops[i].status == "false") {
              this.cart.shops[i].value = "茶歇"
              this.cart.shops[i].mealType = 2
              this.cart.shops[i].status = "true"
              return
            }
          }
        }
      },
      changePropu() {
        var changePopup = true
        this.Bus.$emit('changePopup', changePopup);
      },
      submitData: function() {
      	if(this.totalNum == 0 || !this.totalNum){
      		this.toast({
            message: '当前外卖/茶歇最大推荐数量为0',
            duration: 5000,
          });
          return false;
      	}
        if(this.isSubmitData){
          this.toast({
            message: '正在提交ing..请勿重复操作',
            duration: 2000,
          });
          return;
        }
        let count = true
        let mealTypeSame = "";
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if (this.cart.shops[i].name){
              count = count && true;
          }
          else{
              count = count && false;
              break;
          }
        }
        if (count){
          this.isSubmitData = true
          this.postMyShops()
        }
        else{
            this.messageBox({
              message: `存在未填写项，确认提交`,
              showCancelButton: true,
              cancelButtonText: "取消",
              confirmButtonText: '确定'
            }).then(action => {
              // console.log(action)
              if (action == "confirm") {
                this.isSubmitData = true
                this.postMyShops()
              }
            })
        }
      }
    },
    components: {
      normalHead,
      myCart,
      // step2Input
    }
  }
